import React from 'react'
import './CodingSection.css'
import '../globals.css'
import codingVideo from '../images/wevosoft_video.mp4'
import useAnimation from '../hooks/useAnimation'

function CodingSection() {
  useAnimation()

  return (
    <section className="instantly">
      <div className="instantly__box">
        <div className="instantly__preview">
          <div className="instantly__preview-box">
            <video
              data-aos="zoom-in-up"
              data-aos-duration="600"
              className="instantly__preview-img"
              preload="auto"
              playsInline
              autoPlay
              loop
              muted
            >
              <source src={codingVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CodingSection
