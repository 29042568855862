import React, { useEffect, useMemo } from 'react'
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom'
import './App.css'
import './globals.css'
import Footer from './components/Footer'
import MainPage from './components/pages/MainPage'
import ServicesPage from './components/pages/ServicesPage'
import ContactPage from './components/pages/ContactPage'
import AboutPage from './components/pages/AboutPage'
import { useTranslation } from 'react-i18next'

function App() {
  const location = useLocation()
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const language = location.pathname.split('/')[1]

  useEffect(() => {
    const validLanguages = ['ka', 'en']

    if (!validLanguages.includes(language)) {
      navigate('/ka', { replace: true })
    }

    if (language && language !== i18n.language) {
      i18n.changeLanguage(language)
    }

    document.body.className = `lang-${language}`
    return () => {
      document.body.className = ''
    }
  }, [language, i18n, navigate])

  const titles = useMemo(
    () => ({
      '/ka': 'ვევოსოფტი - ვებ დეველოპმენტის სერვისები',
      '/ka/about': 'ჩვენს შესახებ - ვევოსოფტი',
      '/ka/services': 'ჩვენი სერვისები - ვევოსოფტი',
      '/ka/contact': 'კონტაქტი - ვევოსოფტი',
      '/en': 'Wevosoft - Web Development Services',
      '/en/about': 'About Us - Wevosoft',
      '/en/services': 'Our Services - Wevosoft',
      '/en/contact': 'Contact Us - Wevosoft',
    }),
    []
  )

  useEffect(() => {
    document.title =
      titles[location.pathname] || 'Wevosoft - Web Development Services'
    window.scrollTo(0, 0)
  }, [location, titles])

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/ka" />} />
        <Route path="/:lang" element={<MainPage />} />
        <Route path="/:lang/about" element={<AboutPage />} />
        <Route path="/:lang/services" element={<ServicesPage />} />
        <Route path="/:lang/contact" element={<ContactPage />} />
        {/* Redirect to /ka if language is not valid */}
        <Route path="*" element={<Navigate to="/ka" />} />
      </Routes>
      <Footer currLang={language} />
    </>
  )
}

export default App
