import React from 'react'
import './ChatSection.css'
import profilePicTamo from '../images/profile-photos/profile-photo-tamo.png'
import profilePicShota from '../images/profile-photos/profile-photo-shota.png'
import { useTranslation } from 'react-i18next'

function ChatSection() {
  const [t] = useTranslation('global')

  return (
    <section className="chat">
      <div className="chat-box sectionContainer">
        <div className="chat-box-line">
          <div className="chat-main">
            <h1
              className="chat-main-title "
              data-aos="zoom-in"
              data-aos-duration="700"
            >
              {t('chat-section.chat-title')}
            </h1>
            <p
              className="chat-main-note"
              data-aos="zoom-in"
              data-aos-duration="710"
            >
              {t('chat-section.chat-note')}
            </p>
          </div>

          <div className="chat-phone">
            <div className="chat-list">
              <div className="chat-list-item chat-list-item-s1">
                <img
                  src={profilePicShota}
                  alt="customer"
                  className="chat-list-item-userpic"
                />
                <div className="chat-list-item-note">
                  {t('chat-section.chat-phone-list.item1')}
                </div>
              </div>

              <div className="chat-list-item chat-list-item-s2">
                <img
                  src={profilePicTamo}
                  alt="user"
                  className="chat-list-item-userpic"
                />
                <div className="chat-list-item-note">
                  <div className="chat-list-item-extra">
                    <div className="chat-list-item-txt">
                      {t('chat-section.chat-phone-list.item2')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChatSection
