import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import global_en from './translations/en/global.json'
import global_ka from './translations/ka/global.json'
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next'

// Initialize i18next outside the component
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'ka', // default language
  resources: {
    ka: {
      global: global_ka,
    },
    en: {
      global: global_en,
    },
  },
})

function I18nProvider() {
  const location = useLocation()

  useEffect(() => {
    const lang = location.pathname.split('/')[1] || 'ka'
    i18next.changeLanguage(lang) // Dynamically change language based on route
  }, [location]) // Re-run when location changes

  return <App />
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Router>
      <I18nextProvider i18n={i18next}>
        <I18nProvider />
      </I18nextProvider>
    </Router>
  </React.StrictMode>
)
