import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import './HeroSection.css'
import '../globals.css'
import slidePic1 from '../images/slide1.jpg'
import slidePic2 from '../images/slide2.jpg'
import slidePic3 from '../images/slide3.jpg'
import profilePicTamo from '../images/profile-photos/profile-photo-tamo.png'
import profilePicDito from '../images/profile-photos/profile-photo-dito.png'
import profilePicShota from '../images/profile-photos/profile-photo-shota.png'
import quoteLeft from '../images/icon/quote-left.svg'
import quoteRight from '../images/icon/quote-right.svg'
import { useTranslation } from 'react-i18next'

function HeroSection() {
  const { lang } = useParams()
  const [t] = useTranslation('global')

  const sliderItemList = [
    {
      index: 0,
      text: t('hero-section.slider.slider-by-person1.text'),
      profilePic: profilePicTamo,
      name: t('hero-section.slider.slider-by-person1.name'),
      position: 'CMO, Co-Founder',
      pic: slidePic1,
    },
    {
      index: 1,
      text: t('hero-section.slider.slider-by-person2.text'),
      profilePic: profilePicDito,
      name: t('hero-section.slider.slider-by-person2.name'),
      position: 'CTO, Co-Founder',
      pic: slidePic2,
    },
    {
      index: 2,
      text: t('hero-section.slider.slider-by-person3.text'),
      name: t('hero-section.slider.slider-by-person3.name'),
      profilePic: profilePicShota,
      position: 'CEO, Co-Founder',
      pic: slidePic3,
    },
  ]

  const [currentIndex, setCurrentIndex] = useState(0)
  const intervalRef = useRef(null)

  useEffect(() => {
    const startTimer = () => {
      clearInterval(intervalRef.current)
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderItemList.length)
      }, 5000)
    }

    startTimer()

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [sliderItemList.length])

  const handleSliderItemClick = (index) => {
    setCurrentIndex(index)
  }

  return (
    <div>
      <section className="heroSection">
        <div className="sectionContainer">
          <h1 className="hero-title">{t('hero-section.hero-title')}</h1>
          <p className="hero-note">{t('hero-section.hero-note')}</p>
          <div className="hero-action">
            <NavLink className="hero-action-btn" to={`/${lang}/contact`}>
              <span className="ui-btn__box">
                {t('hero-section.hero-btn-contact')}
              </span>
            </NavLink>
          </div>
        </div>
      </section>
      <div className="slider-container">
        <div className="slider-box">
          <div className="slider-founder-container">
            <p>
              {sliderItemList[currentIndex].text}
              <img src={quoteLeft} alt="quote" className="slider-quote-left" />
              <img
                src={quoteRight}
                alt="quote"
                className="slider-quote-right"
              />
            </p>
            <div className="slider-author-info">
              <img
                src={sliderItemList[currentIndex].profilePic}
                alt="profile pic"
              />
              <h1>{sliderItemList[currentIndex].name}</h1>
              <h2>{sliderItemList[currentIndex].position}</h2>
            </div>
          </div>
          <div className="slider-simple-pic">
            <img
              className="slider-simple-pic-img"
              src={sliderItemList[currentIndex].pic}
              alt="pic"
            />
          </div>
        </div>
        <div className="slider-controller">
          {sliderItemList.map((item, index) => (
            <div
              key={index}
              className={`slider-controller-item ${
                index === currentIndex ? 'slider-controller-item--active' : ''
              }`}
              onClick={() => handleSliderItemClick(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HeroSection
