import Header from "../Header";
import HeroSection from "../HeroSection";
import BuildSection from "../BuildSection";
import ChatSection from "../ChatSection";
import FeatureSection from "../FeatureSection";
import CodingSection from "../CodingSection";

function MainPage() {
  return (
    <>
      <Header />
      <HeroSection />
      <BuildSection />
      <ChatSection />
      <CodingSection />
      <FeatureSection />
    </>
  );
}

export default MainPage;
