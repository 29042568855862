import React from 'react'
import './FeatureSection.css'
import { useTranslation } from 'react-i18next'

function FeatureSection() {
  const [t] = useTranslation('global')

  const features = [
    {
      title: t('features-section.features-title.item1'),
      note: t('features-section.features-note.item1'),
      color: '#ee5495',
    },
    {
      title: t('features-section.features-title.item2'),
      note: t('features-section.features-note.item2'),
      color: '#79b790',
    },
    {
      title: t('features-section.features-title.item3'),
      note: t('features-section.features-note.item3'),
      color: '#9baed9',
    },
    {
      title: t('features-section.features-title.item4'),
      note: t('features-section.features-note.item4'),
      color: '#fcc41d',
    },
  ]

  return (
    <section className="feature-section sectionContainer">
      <ul className="feature-list">
        {features.map((feature, index) => (
          <li key={index} className={`feature-item feature-item-${index + 1}`}>
            {index % 2 === 0 ? (
              <>
                <div
                  className="feature-item-img-container"
                  style={{ backgroundColor: feature.color }}
                ></div>

                <div className="feature-item-text-container">
                  <h1
                    className="feature-item-text-title"
                    style={{ color: feature.color }}
                  >
                    {feature.title}
                  </h1>
                  <h2
                    className="feature-item-text-note"
                    style={{ color: feature.color }}
                  >
                    {feature.note}
                  </h2>
                </div>
              </>
            ) : (
              <>
                <div className="feature-item-text-container">
                  <h1
                    className="feature-item-text-title"
                    style={{ color: feature.color }}
                  >
                    {feature.title}
                  </h1>
                  <h2
                    className="feature-item-text-note"
                    style={{ color: feature.color }}
                  >
                    {feature.note}
                  </h2>
                </div>

                <div
                  className="feature-item-img-container"
                  style={{ backgroundColor: feature.color }}
                ></div>
              </>
            )}
          </li>
        ))}
      </ul>
    </section>
  )
}

export default FeatureSection
