import Header from '../Header'
import EmployeeCard from '../EmployeeCard'
import { useParams } from 'react-router-dom'
import './AboutPage.css'
import {
  LINKEDIN_URL_SHOTA,
  LINKEDIN_URL_DEMETRE,
  LINKEDIN_URL_TAMAR,
  THEME_DARK,
} from '../../consts'
import { Link } from 'react-router-dom'
import employeePhotoDemetre from '../../images/emplyee-photos/employee-demetre.png'
import employeePhotoTamar from '../../images/emplyee-photos/employee-tamar.jpeg'
import employeePhotoShota from '../../images/emplyee-photos/employee-shota.jpeg'
import { useTranslation } from 'react-i18next'

function AboutPage() {
  const { lang } = useParams()
  const [t] = useTranslation('global')

  const employees = [
    {
      name: t('about-page.about-page-employees.name.item1'),
      position: t('about-page.about-page-employees.position.item1'),
      about: t('about-page.about-page-employees.about.item1'),
      linkedinUrl: LINKEDIN_URL_SHOTA,
      photo: employeePhotoShota,
    },
    {
      name: t('about-page.about-page-employees.name.item2'),
      position: t('about-page.about-page-employees.position.item2'),
      about: t('about-page.about-page-employees.about.item2'),
      linkedinUrl: LINKEDIN_URL_DEMETRE,
      photo: employeePhotoDemetre,
    },
    {
      name: t('about-page.about-page-employees.name.item3'),
      position: t('about-page.about-page-employees.position.item3'),
      about: t('about-page.about-page-employees.about.item3'),
      linkedinUrl: LINKEDIN_URL_TAMAR,
      photo: employeePhotoTamar,
    },
  ]

  return (
    <main className="about">
      <Header theme={THEME_DARK} />
      <section className="about-hero">
        <header className="about-title">
          <h1>{t('about-page.about-page-title')}</h1>
        </header>
      </section>
      <section className="sectionContainer">
        <article className="about-info">
          <header className="about-info-description">
            <h2 className="about-info-subtitle subtitle-s1">
              {t('about-page.about-page-subtitles.item1')}
            </h2>
            <p>{t('about-page.about-page-note.item1')}</p>
            <h2 className="about-info-subtitle subtitle-s2">
              {t('about-page.about-page-subtitles.item2')}
            </h2>
            <p>{t('about-page.about-page-note.item2')}</p>
          </header>
          <div className="about-info-contact-btn-wrapper">
            <Link to={`/${lang}/contact`} className="about-info-contact-btn">
              {t('hero-section.hero-btn-contact')}
            </Link>
          </div>
        </article>
        <section className="about-employees">
          {employees.map((employee, index) => (
            <EmployeeCard key={index} employeeInfo={employee} id={index} />
          ))}
        </section>
      </section>
    </main>
  )
}

export default AboutPage
