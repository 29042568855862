import { React } from 'react'
import './BuildSection.css'
import { Link, useParams } from 'react-router-dom'
import 'aos/dist/aos.css'
import laptopImg from '../images/laptop-img.jpeg'
import planetIcon from '../images/icon/planet-outline (1).svg'
import developIcon from '../images/icon/build-section-icon/code-outline.svg'
import internetIcon from '../images/icon/build-section-icon/globe-outline.svg'
import buildIcon from '../images/icon/build-section-icon/build-outline.svg'
import copyIcon from '../images/icon/build-section-icon/copy-outline.svg'
import figmaIcon from '../images/icon/build-section-icon/logo-figma.svg'
import optionIcon from '../images/icon/build-section-icon/options-outline.svg'
import moveIcon from '../images/icon/build-section-icon/move-outline.svg'
import shieldIcon from '../images/icon/build-section-icon/shield-half-outline.svg'
import { useTranslation } from 'react-i18next'
import useAnimation from '../hooks/useAnimation'

function BuildSection() {
  const { lang } = useParams() // Get current language from URL
  const [t] = useTranslation('global')
  useAnimation()

  const buildMainList = [
    {
      buildItemIcon: developIcon,
      buildItemNote: t('build-section.build-list.item1'),
    },
    {
      buildItemIcon: internetIcon,
      buildItemNote: t('build-section.build-list.item2'),
    },
    {
      buildItemIcon: buildIcon,
      buildItemNote: t('build-section.build-list.item3'),
    },
    {
      buildItemIcon: copyIcon,
      buildItemNote: t('build-section.build-list.item4'),
    },
    {
      buildItemIcon: figmaIcon,
      buildItemNote: t('build-section.build-list.item5'),
    },
    {
      buildItemIcon: optionIcon,
      buildItemNote: t('build-section.build-list.item6'),
    },
    {
      buildItemIcon: moveIcon,
      buildItemNote: t('build-section.build-list.item7'),
    },
    {
      buildItemIcon: shieldIcon,
      buildItemNote: t('build-section.build-list.item8'),
    },
  ]

  return (
    <section className="build-section sectionContainer">
      <div className="build-cover">
        <img className="build-laptop-img" src={laptopImg} alt="Laptop" />
        <div className="build__cover-decor">
          <img className="planet-icon" src={planetIcon} alt="planet" />
        </div>
      </div>
      <div className="build-main">
        <h1
          className="build-header"
          data-aos="fade-right"
          data-aos-duration="700"
        >
          {t('build-section.build-header')}
        </h1>
        <h2 className="build-note" data-aos="fade-left" data-aos-duration="710">
          {t('build-section.build-note')}
        </h2>

        <ul className="build-main-list">
          {buildMainList.map((item, index) => (
            <li key={index} className="build-main-list-item">
              <img
                className="build-main-list-item-icon"
                src={item.buildItemIcon}
                alt="file"
              />
              <div className="build-main-list-item-note">
                {item.buildItemNote}
              </div>
            </li>
          ))}
        </ul>
        <div className="build__main-action">
          <Link to={`/${lang}/services`}>
            <span className="ui-btn__box build-button" tabIndex="-1">
              {t('build-section.build-btn')}
            </span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default BuildSection
