import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'
import CloseIcon from '@mui/icons-material/Close'
import facebookIcon from '../images/icon/social-network-icon/logo-facebook.svg'
import instagramIcon from '../images/icon/social-network-icon/logo-instagram.svg'
import linkedinIcon from '../images/icon/social-network-icon/logo-linkedin.svg'
import telIcon from '../images/icon/contact-icon/contact-tel-icon-footer.svg'
import mailIcon from '../images/icon/contact-icon/contact-mail-icon-footer.svg'
import locationIcon from '../images/icon/contact-icon/contact-location-icon-footer.svg'
import { useTranslation } from 'react-i18next'

function Footer({ currLang }) {
  const lang = currLang
  const [t] = useTranslation('global')

  const footerSections = [
    {
      title: t('footer-section.footer-nav-title.item1'),
      note: t('footer-section.footer-nav-note.item1'),
      titleClass: 'footer-section-title-style1',
      linkTo: `/${lang}/about`,
    },
    {
      title: t('footer-section.footer-nav-title.item2'),
      note: t('footer-section.footer-nav-note.item2'),
      titleClass: 'footer-section-title-style2',
      linkTo: `/${lang}/services`,
    },
    {
      title: t('footer-section.footer-nav-title.item3'),
      note: t('footer-section.footer-nav-note.item3'),
      titleClass: 'footer-section-title-style3',
      linkTo: `/${lang}/contact`,
    },
  ]

  const contactInfo = [
    {
      type: 'tel',
      content: '+995 568 87 83 92',
      icon: telIcon,
      link: 'tel:+995878392',
    },
    {
      type: 'mailto',
      content: 'info@wevosoft.com',
      icon: mailIcon,
      link: 'mailto:info@wevosoft.com',
    },
    {
      type: 'location',
      content: t('footer-section.footer-location'),
      icon: locationIcon,
    },
  ]

  const [shownMobileMenu, setShownMobileMenu] = useState(false)
  const navBarRef = useRef()

  useEffect(() => {
    const closeNavBar = (e) => {
      if (!navBarRef?.current?.contains(e.target)) {
        setShownMobileMenu(false)
      }
    }
    document.addEventListener('mousedown', closeNavBar)

    return () => {
      document.removeEventListener('mousedown', closeNavBar)
    }
  }, [])

  const termsSections = Array.from({ length: 6 }).map((_, index) => ({
    title: t(`footer-section.footer-terms.section-title-${index + 1}`),
    note: t(`footer-section.footer-terms.section-note-${index + 1}`),
  }))

  return (
    <footer className="footer">
      <div className="footer-box">
        <div className="footer-row">
          {footerSections.map((section, index) => (
            <div className="footer-section" key={index}>
              <NavLink
                to={section.linkTo}
                className={`footer-section-title ${section.titleClass}`}
              >
                {section.title}
              </NavLink>
              <div className="footer-section-note">{section.note}</div>
            </div>
          ))}
          <div className="footer-section terms-section">
            <button
              onClick={() => {
                setShownMobileMenu(!shownMobileMenu)
              }}
              className="footer-section-title terms-btn"
            >
              {t('footer-section.footer-nav-terms-btn')}
            </button>
          </div>
        </div>
        <address className="footer-addresses">
          <ul className="footer-addresses-list">
            {contactInfo.map((info, index) => (
              <li className="footer-addresses-list-item" key={index}>
                <img
                  className="footer-addresses-list-img"
                  src={info.icon}
                  alt={`${info.type} icon`}
                />
                {info.type === 'tel' && (
                  <a
                    className="footer-addresses-list-item-link"
                    href={info.link}
                  >
                    {info.content}
                  </a>
                )}
                {info.type === 'mailto' && (
                  <a
                    className="footer-addresses-list-item-link"
                    href={info.link}
                  >
                    {info.content}
                  </a>
                )}
                {info.type === 'location' && (
                  <span className="footer-addresses-list-item-link">
                    {info.content}
                  </span>
                )}
              </li>
            ))}
          </ul>
          <div className="social-networks-container">
            <a
              rel="noreferrer"
              href="https://www.facebook.com/people/Wevosoft/61562706014061/"
              target="_blank"
              className="social-network-link"
            >
              <img
                className="social-network-icon"
                src={facebookIcon}
                alt="facebook"
              />
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/wevosoft_international?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              className="social-network-link"
            >
              <img
                className="social-network-icon"
                src={instagramIcon}
                alt="instagram"
              />
            </a>
            <a
              rel="noreferrer"
              href="https://www.linkedin.com/company/wevosoft/"
              target="_blank"
              className="social-network-link"
            >
              <img
                className="social-network-icon"
                src={linkedinIcon}
                alt="linkedin"
              />
            </a>
          </div>
        </address>

        <div className="footer-decor">
          <div className="footer-decor-item footer-decor-item-s1"></div>
          <div className="footer-decor-item footer-decor-item-s2"></div>
        </div>
      </div>

      <div className={`overlay ${shownMobileMenu ? '' : 'hidden'}`}>
        <div className="rights" ref={navBarRef}>
          <button className="close-btn">
            <CloseIcon
              onClick={() => {
                setShownMobileMenu(!shownMobileMenu)
              }}
              className="close-icon"
            />
          </button>

          <h1>{t('footer-section.footer-nav-terms-btn')}</h1>

          {termsSections.map((section, index) => (
            <React.Fragment key={index}>
              <h2>{section.title}</h2>
              <p>{section.note}</p>
            </React.Fragment>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default Footer
