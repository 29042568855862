import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import './Header.css'
import '../globals.css'
import MenuRoundedIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as HeaderLogo } from '../images/logo-main.svg'
import { useTranslation } from 'react-i18next'
import DropdownCard from './DropdownCard'

function Header({ theme }) {
  const [shownMobileMenu, setShownMobileMenu] = useState(false)
  const navBarRef = useRef()
  const navigate = useNavigate()
  const { lang } = useParams()

  const [t, i18n] = useTranslation('global')

  useEffect(() => {
    const closeNavBar = (e) => {
      if (!navBarRef?.current?.contains(e.target)) {
        setShownMobileMenu(false)
      }
    }
    document.addEventListener('mousedown', closeNavBar)
    return () => document.removeEventListener('mousedown', closeNavBar)
  }, [])

  const handlerChangeLanguage = (newLang) => {
    i18n.changeLanguage(newLang)
    const currentPath = window.location.pathname
    const newPath = currentPath.split('/').slice(2).join('/')
    navigate(`/${newLang}/${newPath}`)
  }

  return (
    <>
      <header
        ref={navBarRef}
        className={`header sectionContainer ${theme} ${
          shownMobileMenu ? 'nav-open' : ''
        }`}
      >
        <NavLink to={`/${lang}`} className="header-logo-link">
          <HeaderLogo className={`header-logo ${theme}`} />
        </NavLink>
        <nav className="main-nav">
          <ul className="main-nav-list">
            <li className="main-nav-list-item">
              <NavLink
                className="main-nav-list-item-link"
                to={`/${lang}/about`}
              >
                {t('nav-bar.item1')}
              </NavLink>
            </li>
            <li className="main-nav-list-item">
              <NavLink
                className="main-nav-list-item-link"
                to={`/${lang}/services`}
              >
                {t('nav-bar.item2')}
              </NavLink>
            </li>
            <li className="main-nav-list-item">
              <NavLink
                className="main-nav-list-item-link"
                to={`/${lang}/contact`}
              >
                {t('nav-bar.item3')}
              </NavLink>
            </li>
            <DropdownCard
              mobileMenuOpen={shownMobileMenu}
              theme={theme ? '#fff' : ''}
              onChangeLanguage={handlerChangeLanguage}
            />
          </ul>
          {shownMobileMenu ? (
            <>
              <button className="btn-mobile-nav btn-close-position">
                <CloseIcon
                  onClick={() => {
                    setShownMobileMenu(!shownMobileMenu)
                  }}
                  className="menu-icon"
                />
              </button>
              <h1 className="mobile-nav-logo">WEVOSOFT</h1>
            </>
          ) : (
            ''
          )}
        </nav>
        <button className="btn-mobile-nav btn-mobile-nav-position">
          <MenuRoundedIcon
            onClick={() => {
              setShownMobileMenu(!shownMobileMenu)
            }}
            className="menu-icon"
          />
        </button>
      </header>
    </>
  )
}

export default Header
