import AOS from 'aos'
import { useEffect } from 'react'

function useAnimation() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Easing function
      once: false, // Allow animation to trigger on scroll up as well
    })

    // Reinitialize AOS on window resize or any other event that might require re-triggering the animations
    window.addEventListener('resize', AOS.refresh)

    return () => {
      window.removeEventListener('resize', AOS.refresh)
    }
  }, [])
}

export default useAnimation
