import React from 'react'
import 'aos/dist/aos.css'
import useAnimation from '../hooks/useAnimation'

function ServiceCard({ icon, title, note, customStyleClass }) {
  useAnimation()
  return (
    <div
      className={`service-card ${customStyleClass}`}
      data-aos="zoom-in"
      data-aos-duration="700"
    >
      <div className="service-card-icon-wrapper">{icon}</div>
      <h1 className="service-card-title">{title}</h1>
      <p className="service-card-note">{note}</p>
    </div>
  )
}

export default ServiceCard
