import './ServicePage.css'
import Header from '../Header'
import { THEME_DARK } from '../../consts'
import { ReactComponent as DevelopIcon } from '../../images/icon/build-section-icon/code-outline.svg'
import { ReactComponent as InternetIcon } from '../../images/icon/build-section-icon/globe-outline.svg'
import { ReactComponent as BuildIcon } from '../../images/icon/build-section-icon/build-outline.svg'
import { ReactComponent as CopyIcon } from '../../images/icon/build-section-icon/copy-outline.svg'
import { ReactComponent as FigmaIcon } from '../../images/icon/build-section-icon/logo-figma.svg'
import { ReactComponent as OptionIcon } from '../../images/icon/build-section-icon/options-outline.svg'
import { ReactComponent as MoveIcon } from '../../images/icon/build-section-icon/move-outline.svg'
import { ReactComponent as ShieldIcon } from '../../images/icon/build-section-icon/shield-half-outline.svg'
import ServiceCard from '../ServiceCard'
import { useTranslation } from 'react-i18next'

function ServicePage() {
  const [t] = useTranslation('global')

  const services = [
    {
      icon: <DevelopIcon />,
      title: t('services-page.services.item1.title'),
      note: t('services-page.services.item1.note'),
      customStyleClass: 'service-card-style-1',
    },
    {
      icon: <InternetIcon />,
      title: t('services-page.services.item2.title'),
      note: t('services-page.services.item2.note'),
      customStyleClass: 'service-card-style-2',
    },
    {
      icon: <BuildIcon />,
      title: t('services-page.services.item3.title'),
      note: t('services-page.services.item3.note'),
      customStyleClass: 'service-card-style-3',
    },
    {
      icon: <CopyIcon />,
      title: t('services-page.services.item4.title'),
      note: t('services-page.services.item4.note'),
      customStyleClass: 'service-card-style-4',
    },
    {
      icon: <FigmaIcon />,
      title: t('services-page.services.item5.title'),
      note: t('services-page.services.item5.note'),
      customStyleClass: 'service-card-style-1',
    },
    {
      icon: <OptionIcon />,
      title: t('services-page.services.item6.title'),
      note: t('services-page.services.item6.note'),
      customStyleClass: 'service-card-style-2',
    },
    {
      icon: <MoveIcon />,
      title: t('services-page.services.item7.title'),
      note: t('services-page.services.item7.note'),
      customStyleClass: 'service-card-style-3',
    },
    {
      icon: <ShieldIcon />,
      title: t('services-page.services.item8.title'),
      note: t('services-page.services.item8.note'),
      customStyleClass: 'service-card-style-4',
    },
  ]

  return (
    <>
      <Header theme={THEME_DARK} />
      <section className="services-hero">
        <header>
          <h1 className="services-hero-title">
            <div className="services-hero-title-in">
              {t('services-page.services-page-title')}
            </div>
          </h1>
          <p className="services-hero-note">
            {t('services-page.services-page-note')}
          </p>
        </header>
      </section>
      <section className="services">
        {services.map((service) => (
          <ServiceCard
            icon={service.icon}
            title={service.title}
            note={service.note}
            key={service.title}
            customStyleClass={service.customStyleClass}
          />
        ))}
      </section>
    </>
  )
}

export default ServicePage
