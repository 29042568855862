import React from 'react'
import './EmployeeCard.css'
import { ReactComponent as LinkedinLogo } from '../images/icon/social-network-icon/logo-linkedin.svg'
import 'aos/dist/aos.css'
import useAnimation from '../hooks/useAnimation'

function EmployeeCard({ employeeInfo, id }) {
  const { name, position, about, linkedinUrl, photo } = employeeInfo
  useAnimation()

  return (
    <div
      className="employee-card"
      data-aos="zoom-in"
      data-aos-duration={500 + id * 20}
    >
      <img className="employee-card-avatar" src={photo} alt="employee" />
      <h1 className="employee-card-name">{name}</h1>
      <h2 className="employee-card-position">{position}</h2>
      <p className="employee-card-about">{about}</p>
      <a
        className="employee-card-linkedin"
        href={linkedinUrl}
        rel="noreferrer"
        target="_blank"
      >
        <LinkedinLogo className="linkedin-logo" />
        <span>Linkedin Profile</span>
      </a>
    </div>
  )
}

export default EmployeeCard
