import React from 'react'
import { GlobalOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import flagGe from '../images/icon/flags/flag_ge.svg'
import flagEn from '../images/icon/flags/flag_en.svg'
import './DropdownCard.css'

function DropdownCard({ onChangeLanguage, theme, mobileMenuOpen }) {
  const items = [
    {
      key: '1',
      label: (
        <h4 className="lang-dropdown-item">
          <img className="flag-icon" src={flagGe} alt="flag_ge" /> ქართული
        </h4>
      ),
      onClick: () => onChangeLanguage('ka'),
    },
    {
      key: '2',
      label: (
        <h4 className="lang-dropdown-item">
          <img className="flag-icon" src={flagEn} alt="flag_en" /> English
        </h4>
      ),
      onClick: () => onChangeLanguage('en'),
    },
  ]

  return (
    <div className={`${mobileMenuOpen ? 'nav-open' : ''} main-nav-list-item`}>
      <Dropdown
        menu={{
          items,
        }}
      >
        <Space>
          <GlobalOutlined
            style={{
              fontSize: '22px',
              marginLeft: '20px',
              color: `${theme ? theme : '#282d33'}`,
            }}
          />
        </Space>
      </Dropdown>
      <style>
        {`

        .nav-open .anticon-global{
          color: #fff !important;
          font-size: 28px !important;
          padding: 0 46px !important;
        }
        .ant-space{
          display: flex;
          justify-content: start;
          height: 100%;
        }

        .ant-dropdown .ant-dropdown-menu {
          background-color: #282d33 !important;

        }
        .ant-dropdown-menu-item {
          color: #ffffff !important; 
          transition: transform 0.5s ease; 
        }
        .ant-dropdown-menu-item:hover {
          transform: scale(1.02); 
        }

        .ant-space-item {
          display: flex;
        }
        `}
      </style>
    </div>
  )
}

export default DropdownCard
